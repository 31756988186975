.footer, .footer a, .footer a:focus, .footer a:hover {
    color: #fff;
}
.footer {
    background: #463f5e;
    padding: 30px 0;
    &-fixed{
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}

.social__link {
    text-decoration: none;
    text-align: center;
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 20px;
    border-radius: 50%;
    color: #68627a!important;
    transition: color .25s ease-in-out;
    margin-left: 10px;
    &:hover{
        background-color: #cc3637;
    }
}

[class*="socicon-"], [class^=socicon-] {
    font-family: Socicon !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

.socicon{
    &-vkontakte{
        &:before{
            content: url('../icons/vk.svg');
        }
    }
    &-instagram{
        &:before{
            content: url('../icons/inst.svg');
        }
    }
}