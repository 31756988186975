@function max($numbers...) {
	@return m#{a}x(#{$numbers});
}

@function min($numbers...) {
	@return m#{i}n(#{$numbers});
}
@import 'fonts';

*{
 box-sizing: border-box;
 font-family: 'Manrope', sans-serif;
 font-weight: normal;
}

h1,h2,h3,h4,h5,h6,p{
	margin: 0;
}
@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1320px !important;
    }
}
body {
    font-family: Inter, sans-serif;
    color: #463f5e;
    font-size: 16px;
    overflow-x: hidden;
    position: relative;
}
main{
	overflow: hidden;
    min-height: 80vh;
}
section, article{
    &:first-child{
        padding-top: 152px;
    }
    &:last-child{
        padding-bottom: 70px;
    }
    @media (max-width: 768px){
        &:first-child{
            padding-top: 0;
        }
    }
}
input{
	outline: none;
	&:focus,
	&:focus-within,
	&:active,
	&:visited{
		outline: none;
	}
}
button{
	border: none;
	outline: none;
	&:focus,
	&:focus-within,
	&:active,
	&:visited{
		border: none;
		outline: none;
		&-visible{
			border: none;
			outline: none;
		}
	}
}

a:focus, a:hover {
    color: inherit;
    text-decoration: none;
}

a {
    color: #cc3637;
    outline: none;
    text-decoration: underline;
}

.wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity .25s ease-in-out,transform 0s ease-in-out .25s;
    background-color: #000;
}
.wrapper--active {
    transform: translateX(0);
    opacity: .5;
    transition: opacity .25s ease-in-out;
}

.burger {
    width: 34px;
    height: 25px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    vertical-align: middle;
}
.burger:after, .burger:before {
    content: "";
}
.burger:after, .burger:before, .burger span {
    background: #463f5e;
    height: 3px;
}
.burger--white:after, .burger--white:before, .burger--white span {
    background: #fff;
    transition: opacity .25s ease-in-out,transform .25s ease-in-out;
}
.burger--active:before {
    transform: rotate(45deg) translateY(11px) translateX(4.5px);
}
.burger--active span {
    opacity: 0;
}
.burger--active:after {
    transform: rotate(-45deg) translateY(-11px) translateX(4.5px);
}
.filter-btn .burger--active::before,
.filter-btn .burger--active::after{
    transition: transform .25s ease-in-out;
}
.filter-btn .burger--active:after {
    transform: rotate(-45deg) translateY(-5px) translateX(0.5px);
}
.filter-btn .burger--active:before {
    transform: rotate(45deg) translateY(5px) translateX(0.5px);
}

.menu {
    font-weight: 500;
    font-size: 12.5px;
    line-height: 15px;
    letter-spacing: .05em;
    text-transform: uppercase;
}
.menu__list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: space-around;
}

.menu__link {
    text-decoration: none;
}
.menu__link, .menu__link:focus, .menu__link:hover {
    font-weight: 600;
    color: #3c1f8b;
}
.menu__item:first-child {
    margin-left: 0;
}
.menu__item {
    margin: 0 5px;
}
.mobile-menu {
    background: #463f5e;
    padding: 12px 0;
    display: none;
    position: relative;
    transition: box-shadow .25s ease-in-out;
}
.mobile-menu--fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 4;
    box-shadow: 0 1px 5px rgba(0, 0, 0,  .5);
}
.mobile-menu__wrapper {
    position: absolute;
    background: #fff;
    top: 50px;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 20px 0 50px;
    text-align: center;
    transform: translateX(-100%);
    transition: transform .25s ease-in-out;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .5);
}
.mobile-menu__wrapper--active {
    transform: translateX(0);
}
.mobile-menu__list {
    list-style: none;
    padding: 0;
}
.mobile-menu__item {
    font-weight: 500;
    font-size: 16px;
    line-height: 70px;
    letter-spacing: .05em;
    text-transform: uppercase;
}
.mobile-menu__link {
    color: #3c1f8b;
    text-decoration: none;
}

.social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.socicon-vkontakte:before {
    content: "\e095";
}
.socicon-instagram:before {
    content: "\e044";
}
select{
    appearance: none;
    background: url('../icons/dropdown-arrow.svg') center/cover no-repeat;
    background-size: 24px 11px;
    background-position: center right 17px;
    position: relative;
    option{
        padding: 10px 5px;
    }
}
@media (max-width: 575px){
	.social {
		justify-content: center;
	}
	
}

@import "./bootstrap";
@import '../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox';
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import 'blocks/header';
@import 'blocks/personal-cabinet';
@import 'blocks/footer';

body{
    background-color: #E5E5E5;
}

.log-in{
    background: #FFFFFF;
    width: 100vw;
    min-height: 100vh;
    position: relative;
    &__form{
        width: 410px;
        margin-left: auto;
        margin-right: 62%;
        padding-top: 140px;
    }
    h1{
        color: #352A7C;
        font-weight: bold;
        font-size: 48px;
    }
    .personal__input{
        margin-bottom: 20px;
    }
    .btn{
        min-width: 190px;
        height: 49px;
        font-size: 17px;
    }
}
.color-purple{
    color: #352A7C;
}
.bg-transparent{
    background: transparent;
}
.bg-figures{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    background: url('../img/log-in.jpg');
}
.checkbox{
    &+label{
        position: absolute;
        left: 15px;
        width: 27px;
        height: 27px;
        background: #fff;
        border: 1px solid #C8C5D8;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        flex-grow: 0;
        cursor: pointer;
    }
    &:checked{
        &+label::before{
            content: url('../icons/check.svg');
        }
    }
}
input[type='checkbox']{
    display: none;
}
@media (max-width: 1200px){
    .log-in{
        &__form{
            margin-right: 55%;
        }
    }
}
@media (max-width: 992px){
    .bg-figures{
        display: none;
    }
    .log-in{
      &__form{
          margin-right: auto;
      }
    }
}
@media (max-width: 992px){
    .log-in{
      &__form{
          padding-top: 0;
      }
    }
}
@media (max-width: 576px){
    .log-in{
        &__form{
            max-width: 280px;
        }
        .form__row{
            margin-top: 0;
        }
        .btn--login{
            width: 100%;
        }
        .form{
            text-align: center;
            &__link{
                display: block;
                margin-top: 30px;
            }
        }
    }
}