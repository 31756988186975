.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 4;
    background: #E5E5E5;
    transition: box-shadow .25s ease-in-out;
}
.header--shadow {
    box-shadow: 0 1px 5px rgba(0, 0, 0, .5);
}

.logo {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
}
.logo__img {
    flex-shrink: 0;
    width: 68px;
    height: 68px;
    box-shadow: 0 2px 10px #e1e7ef;
    border-radius: 50%;
}
.logo__img img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
}
.logo__text {
    width: 100%;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #463f5e;
    margin-left: 15px;
    text-align: left;
}
.logo--white .logo__text {
    color: #fff;
}
.logo--white .logo__img {
    box-shadow: none;
}


.phone {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #463f5e;
    text-decoration: none;
}
.btn {
    display: inline-flex;
    min-width: 170px;
    height: 40px;
    background: #cc3637;
    border-radius: 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    border: 0;
    cursor: pointer;
}
.btn:focus, .btn:hover {
    color: #fff;
    outline: none;
}
.btn:focus, .btn:hover {
    color: #fff;
    outline: none;
}
.btn, .phone--white, .phone--white:focus, .phone--white:hover {
    color: #fff;
}
.header__link-callback {
    display: none;
}

@media (max-width: 1399px){
    .logo__text {
        font-size: 9px;
        line-height: 17px;
    }
}
@media (max-width: 1199px){
    .menu {
        position: fixed;
        top: 0;
        left: -350px;
        width: 320px;
        height: 100%;
        padding: 30px;
        background: #463f5e;
        z-index: 6;
        box-shadow: 1px 0 5px rgba(0, 0, 0, .5);
        font-size: 16px;
        transition: transform .25s ease-in-out;
    }
    .menu--active {
        transform: translateX(350px);
    }
    .menu__list {
        flex-direction: column;
    }
    .menu__item {
        margin: 0 0 30px;
    }
    .menu__link, .menu__link:focus, .menu__link:hover {
        color: #fff;
    }
}
@media (max-width: 767px){
    body {
        padding-top: 120px;
    }
}
@media (max-width: 575px){
    body {
        padding-top: 0;
    }
    .wrapper {
        display: none;
    }
    .header {
        position: static;
        box-shadow: none;
    }
    .mobile-menu {
        display: block;
    }
}
@media (min-width: 576px){
    .header--shadow .logo__img {
        width: 48px;
        height: 48px;
    }
    .header--shadow .btn {
        display: none;
    }
    .header--shadow .header__link-callback {
        display: inline-block;
    }
}