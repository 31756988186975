.tabs{
    &__list{
        list-style: none;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        white-space: nowrap;
    }
    &__item{
        width: 325px;
        color: #352A7C;
        position: relative;
        font-size: 22px;
        cursor: pointer;
        &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 4px;
            background-color: rgba(200, 198, 213, 0.5);
        }
        &:hover,
        &--active{
            color: #CC3637;
            &::after{
                background-color: #CC3637;
            }
        }
    }
    &-content{
        display: none;
        &--active{
            display: block;
        }
        min-height: 500px;
    }

    &-btns{
        .tabs__item{
            width: 135px;
            font-size: 17px;
            font-weight: 600;
            background: #FFFFFF;
            border: 2px solid transparent;
            box-shadow: 0px 0px 30px rgba(224, 224, 224, 0.8);
            border-radius: 10px;
            padding: 20px 13px;
            text-align: center;
            border-radius: 10px;
            &--active{
                border-color: #CC3637;
            }
            &:after{
                content: none;
            }
        }
    }
}

.personal{
    &__inner{
        background-color: #fff;
        box-shadow: 0px 0px 50px rgba(194, 194, 194, 0.15);
        border-radius: 10px;
    }
    &__table{
        background-color: #F9F9F9;
        border-radius: 5px;
        margin-top: 50px;
        table{
            width: 100%;

        }
    }
    &__tab{
        text-align: center;
        padding: 28px 35px;
    }
    &__content{
        padding: 52px 45px 45px;
    }
    &__input{
        color: #898693;
        margin-top: 5px;
        width: 100%;
        padding: 16px 25px;
        border: 1px solid #C8C5D8;
        border-radius: 5px;
    }
    &__form{
        margin: 75px 0 80px;
        label{
            display: block;
            text-align: left;
            color: #463F5E;
        }
        &-text,
        select{
            border-radius: 5px;
        }
        &-checkbox{
            margin-right: 15px;
        }
        &-btn{
            margin-top: 17px;
        }
        &--search{
            margin: 0;
            .form__row{
                margin-top: 25px;
            }
        }
        &--date{
            input[type='date']{
                max-width: 43%;
            }
        }
        &--persdata{
            div{
                max-height: 75px;
            }
            input{
                max-height: 39px;
            }
        }
    }
    &__row{
        &-chb{
            margin-bottom: 20px;
            label{
                margin-bottom: 0;
            }
        }
    }
    &__parameters{
        .form__row{
            margin-bottom: 20px;
            margin-top: 0;
        }
        &-desk{
            display: block;
        }
        &-mobile{
            display: none;
        }
    }
}
h2{
    color: #352A7C;
    font-weight: bold;
    font-size: 36px;
    letter-spacing: 0.01em;
}
.subtitle{
    color: #352A7C;
    font-weight: 600;
    font-size: 19px;
    letter-spacing: -0.07em;
}

.form{
    color: #463F5E;
    &__link{
        font-weight: 600;
        font-size: 16px;
        text-decoration-line: underline;        
        color: #352A7C; 
        &:hover{
            text-decoration: none;
        }
    }
    &__row{
        margin-top: 40px;
        margin-bottom: 25px;
    }
    &__col{
        flex-direction: column;
        .personal__form-checkbox{
            margin-bottom: 25px;
        }
    }
    &__input{ 
        font-size: 17px;
        background: #FFFFFF;
        border: 1px solid #C8C5D8;
        border-radius: 5px;
        padding: 5px 10px;
    }
    &__checkbox {
        padding-left: 52px;
    }
    &-arrow{
        height: 50px;
        display: flex;
        align-items: center;
    }
}

.btn{
    &--big{
        font-size: 17px;
        padding: 23px 20px;
        text-align: center;
        background-color: #CC3637;
        color: #fff;
        border-radius: 10px;
        letter-spacing: 0.03em;
        padding: 31px 20px;
        max-width: 230px;
        width: 100%;
        line-height: calc( 100% - 17px );
    }
    &--search{
        min-width: 130px;
        padding: 0;
    }
}
.overflow-x{
    overflow-x: auto;
}
table{
    color: #463F5E;
    border: 1px solid #C8C5D8;
    max-width: 1200px;
    th{
        font-size: 18px;
        font-weight: 600;
        border-bottom: 1px solid rgba(148, 143, 180, 0.5);
    }
    tr{
        font-size: 17px;
    }
    div{
        padding: 16px 13px 5px 55px;
        text-align: left;
        color: #463F5E;
    }
    tr:last-child{
        div{
            padding-bottom: 20px;
        }
    }
}
.cross{
    position: absolute;
    width: 23px;
    height: 21px;
    right: 20px;
    bottom: 10px;
    cursor: pointer;
    &-icon{
        width: 100%;
    }
}
.filter{
    &-container{
        width: 960px;
        margin: 0 auto;
        padding: 0 15px;
    }
    &-btn{
        font-size: 15px;
        color: #898693;
        background-color: #F9F9F9;
        border: 1px solid #C8C5D8;
        border-radius: 30px;
        padding: 9px 24px;
        position: relative;
        display: flex;
        margin-left: 15px;
    }
    &-burger{
        width: 15px;
        height: 10px;
        margin: auto 11px auto 0;
        &:before,&:after,span{
            height: 2px;
            width: 15px;
            background-color: #C8C5D8;
        }
    }
    &-mobile{
        width: 100vw;
        height: 100vh;
        position: fixed;
        background-color: #fff;
        z-index: 10;
        top: 0;
        transform: translateX(-100%);
        transition: transform .25s ease-in-out;
        &--active{
            transform: translateX(0);
        }
        &__header{
            position: relative;
            background-color: #E5E5E5;
            padding: 31px;
            h2{
                margin-top: 0;
            }
        }
        
        &__content{

        }
    }
}
@media (min-width: 1200px){
    .filter{
        &-container{
            max-width: 1320px;
        }
    }
}
@media (max-width: 1200px){
    .cross{
        width: 17px;
        height: 17px;
        bottom: 15px;
        right: 10px;
    }
    .filter{
        &-btn{
            padding: 9px 30px 9px 15px;
        }
        &-mobile{
            button{
                width: 45%;
                justify-content: center;
            }
            &__cross{
                width: 23px;
                height: 21px;
                top: 50%;
                bottom: auto;
                transform: translateY(-50%);
                right: 20px;
            }
        }
    }
}
@media (max-width: 992px){
    .personal{
        &__form{
            margin: 35px 0 20px;
        }
        &__input{
            margin-bottom: 20px;
        }
        &__row{
            &-chb{
                label{
                    margin-bottom: 15px;
                }
            }
        }
        &__table{
            margin-top: 20px;
        }
        &__parameters{
            &-desk{
                display: none;
            }
            &-mobile{
                display: block;
            }
        }
    }
    table{
        th{
            font-size: 15px;
        }
        tr{
            font-size: 15px;
        }
        div{
            padding: 10px 15px 10px 30px;
            white-space: nowrap;
        }
    }
    .filter{
        &-container{
            width: 720px;
        }
    }
}
@media (max-width: 768px){
    .filter{
        &-container{
            width: 540px;
        }
    }
}
@media (max-width: 576px){
    .subtitle, label{
        font-size: 15px;
    }
    .personal{
        &__input{
            padding: 10px 15px;
            font-size: 15px;
        }
        &__content{
            padding: 30px 20px 45px;
        }
        &__tab{
            padding: 20px 15px;
            font-size: 18px;
        }
        &__form{
            &--date{
                input[type='date']{
                    font-size: 14px;
                    -webkit-calendar-picker-indicator {
                        color: transparent;
                        opacity: 1;
                        background-size: contain;
                      }
                }
            }
        }
    }
    h2{
        font-size: 24px;
        margin-top: 60px;
    }
    .filter{
        &-btn{
            border-radius: 10px;
            width: 133px;
            justify-content: center;
        }
        &-container{
            width: 100%;
            width: 100%;
        }
    }
}
